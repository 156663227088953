@import '../../scss-mixins/mixins';

#ValuesAnswers {
  width: 100%;

  .mobile {
    @include desktop_only {
      display: none;
    }
  }

  .desktop {
    @include mobile_only {
      display: none;
    }
  }

  .answers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 150px;

    @include desktop_only {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  img {
    margin-top: 0;
    margin-bottom: 0;
    height: 39px;
    margin-left: -8px;

    @media (min-width: 750px) {
      height: 49px;
      margin-left: 0;
      //margin-top: 10px;
      //margin-bottom: 10px;
    }

  }

  .placeholder {
    height: 39px;
    @media (min-width: 750px) {
      height: unset;
      //width: 175px;
      flex: 1 1 0px;
    }
  }

  img.definitely {
    width: 141px;
    @media (min-width: 750px) {
      width: unset;
    }
  }

  img.probably {
    @media (min-width: 750px) {
    }
  }

  img.doesnt-matter {
    @media (min-width: 750px) {
    }
  }

  img.probably-not {
    @media (min-width: 750px) {
    }
  }

  img.definitely-not {
    @media (min-width: 750px) {
    }
  }

  .mom-container {
    width: 165px;
    //height: 462px;
    display: flex;
    justify-content: center;
    z-index: 2;

    @include desktop_only {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  img.desktop {
    margin-top: 11px;
    margin-right: 20px;
  }

  img.mom {
    margin-top: 0;
    height: 300px;
    width: unset;
    height: 51vh;

    @include desktop_only {
      height: 380px;
    }

    @media (min-width: 940px) {
      margin-top: 25px;
    }
  }

  img.mom.definitely {
    margin-top: -10px;
    margin-left: 20px;
    margin-right: -20px;
    @include desktop_only {
      margin-top: 0;
      margin-left: 0;
    }
    @media (min-width: 940px) {
      margin-top: 25px;
      height: 360px;
    }
  }

  img.mom.probably {
    @include desktop_only {
      margin-left: 125px;
    }
  }

  img.mom.doesnt-matter {
    margin-top: 12px;
    @include desktop_only {
      margin-left: 260px;
      margin-top: 10px;
    }
    @media (min-width: 940px) {
      margin-top: 38px;
      margin-bottom: -20px;
    }
  }

  img.mom.probably-not {
    margin-top: 12px;
    @include desktop_only {
      margin-left: 430px;
      margin-top: 10px;
    }
    @media (min-width: 940px) {
      margin-bottom: -20px;
      margin-top: 40px;
    }
  }

  img.mom.definitely-not {
    height: 47vh;
    margin-left: 15px;
    @include desktop_only {
      margin-left: 600px;
      height: 350px;
    }
    @media (min-width: 940px) {
      margin-top: 20px;
      height: 340px;
    }
  }

  button {
    margin-top: 9px;
    margin-bottom: 9px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    text-align: left;
    line-height: 21px;

    @include desktop_only {
      //width: 175px;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  .definitely button {
    width: 111px;
  }

  .probably button {
    width: 97.5px;
  }

  .doesnt-matter button {
    width: 153px;
  }

  .probably-not button {
    width: 144px;
  }

  .definitely-not button {
    width: 157px;
  }

  button.q1 {
    color: #932091;
  }

  button.q2 {
    color: #F17604;
  }

  button.q3 {
    color: #DD077B;
  }

  button.q4 {
    color: #68A811;
  }

  button.q5 {
    color: #EBC000;
  }

  button.q6 {
    color: #0075B9;
  }


  p.select {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-align: left;
    line-height: 21px;
    margin-bottom: 10px;
  }

  .mom-and-answers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include desktop_only {
      flex-direction: column;
    }
  }
}
