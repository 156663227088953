@import '../../scss-mixins/mixins';

#Study {
  //background-color: $light-blue;
  background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
  height: 100vh;
  padding: 30px;

  h1 {
    font-size: 48px;
    font-weight: bold;
    line-height: 56px;
  }

  p {
    font-size: 24px;
    font-weight: bold;
  }

  .input {
    margin: 20px 0;
  }

  label {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      height: 26px;
      font-size: 24px;
    }
  }

  .next {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    img {
      width: 78px;
    }
  }
}
