@import '../../scss-mixins/mixins';

#Summary {
  padding: 20px 12px 15px;
  background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
  //height: 100vh;

  @include desktop_only {
    position: relative;
    background-image: none;
    padding: 0;

    .mobile {
      display: none;
    }
  }

  @include mobile_only {
    .desktop {
      display: none;
    }
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    margin-bottom: 5px;
    line-height: 50px;

    @include desktop_only {
      margin-top: 110px;
      margin-left: 80px;
      font-size: 110px;
      color: white;
      margin-bottom: 40px;
    }

    @media (min-width: 940px) {
      margin-left: 100px;
      margin-top: 160px;
    }
  }

  h3 {
    @include desktop_only {
      font-weight: bold;
      font-size: 34px;
    }
  }

  p, span {
    margin-top: 15px;
    line-height: 21px;

    @include desktop_only {
      margin-top: 25px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  span.url {
    font-weight: bold;
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    @include desktop_only {
      padding: 30px 30px 0;
      font-size: 30px;
      line-height: 32px;
      background-image: url('/Summary_desktop.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 100% 0;
    }
  }

  .answers {
    font-size: 16px;

    @media (min-width: 940px) {
      font-size: 20px;
    }

    p.question {
      font-weight: bold;
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 5px;

      @include desktop_only {
        margin-top: 30px;
        font-size: 24px;
        line-height: 28px;
      }
    }

    .answer {
      font-weight: bold;
      @include desktop_only {
        margin-top: 15px;
      }
    }

    .q1 .answer {
      color: #932091;
    }

    .q2 .answer {
      color: #F17604;
    }

    .q3 .answer {
      color: #DD077B;
    }

    .q4 .answer {
      color: #68A811;
    }

    .q5 .answer {
      color: #EBC000;
    }

    .q6 .answer {
      color: #0075B9;
    }

    span.true {
      padding: 6px;
      background: #BCE0DD;
      border-radius: 5px;
    }

    @include desktop_only {
      background: white;
      font-size: 18px;
      margin: 25px 25px 0;
      font-weight: normal;
      border: 2px solid #0075B9;
      border-radius: 8px;
      padding: 15px 15px 20px;

      span {
        margin-right: 30px;
        color: black;
      }
      span.true {
        margin-right: 22px;
        margin-left: -6px;
      }
    }

    @media (min-width: 940px) {
      span {
        margin-right: 55px;
        color: black;
      }
      span.true {
        margin-right: 46px;
        margin-left: -6px;
      }
    }
  }

  .best-wishes {
    left: 0;
    margin-top: 15px;
    padding: 10px;
    background: rgba(115,196,216,0.75);
    border: 1px solid #81BCCA;
    border-radius: 8px;

    img {
      margin-top: -75px;
      height: 200px;
    }

    @include desktop_only {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      background: #BCE0DD;
      margin-top: 0;
      border: none;
      border-radius: 0;
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      color: #FBF6F6;
      text-align: center;
      line-height: 33px;
      text-shadow: 0 2px 8px rgba(0,0,0,0.50);

      @include desktop_only {
        margin-top: 30px;
        color: white;
        height: 150px;
        text-shadow: none;
        font-size: 36px;
      }
    }
  }

  .notes {
    background: white;
    padding: 25px 50px;

    p {
      @include desktop_only {
        font-size: 24px;
      }
      @media (min-width: 940px) {
        font-size: 24px;
      }
    }

    p.dots {
      @media (min-width: 940px) {
        font-size: 30px;
      }
    }
  }

  p.notes {
    line-height: 50px;
  }

  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
