@import '../../scss-mixins/mixins';

#Popup {
  position: absolute;
  //margin: 10px 12px;
  margin-left: auto;
  margin-right: auto;
  top: 15px;
  left: 10px;
  right: 10px;

  background: white;
  border: 1px solid #8DC63F;
  box-shadow: 0 2px 7px 0 rgba(141,198,63,0.56);
  border-radius: 9px;


  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  @include desktop_only {
    width: 50%;
    margin: auto;
    z-index: 10;

    @media (min-width: 940px) {
      width: 40%;
    }
  }

  .close {
    align-self: flex-end;
    margin-top: 10px;
    margin-right: 10px;
  }

}