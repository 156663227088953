@import '../../scss-mixins/mixins';

#ValuesQ3 {
    /*
  .headers {
    @include desktop_only {
      width: 70%;
    }
    @media (min-width: 940px) {
      width: 70%;
    }
  }
  */

  .headers {
    @media (min-width: 400px) {
      width: 90%;
    }
    @media (min-width: 940px) {
      width: 100%;
    }
  }
  h1 {
    @include desktop_only {
      font-size: 36px;
    }
    @media (min-width: 940px) {
      width: 100%;
    }
  }
}
