@import '../../scss-mixins/mixins';

#MainNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  align-items: center;


  @include mobile_only {
    display: none;
  }

  .item {
    margin-right: 20px;
    font-size: 12px;
    font-weight: bold;
    color: black;
  }

  .item.selected {
    color: white;
    border: 1px solid white;
    padding: 3px;
    border-radius: 3px;
  }
}