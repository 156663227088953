$light-blue: #F1FCFF;
$schedule-purple: #5852A0;
$white-text: #FBF6F6;
$wait-green: #137B87;
$pain-green: #589308;

@mixin max_desktop_width {
  max-width: 940px;
}

@mixin desktop_only {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobile_only {
  @media (max-width: 767px) {
    @content;
  }
}
