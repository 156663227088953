@import '../../scss-mixins/mixins';

#ProsCons {
  //background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
  background-color: #C8E9F1;

  @media (min-width: 940px) {
    height: 840px;
  }

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    text-align: left;

    @include desktop_only {
      margin-left: 8px;
      font-size: 38px;
    }

    @media (min-width: 940px) {
      margin-left: 10px;
      font-size: 48px;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    align-self: flex-start;
    @include desktop_only {
      align-self: center;
      font-size: 38px;
    }
    @media (min-width: 940px) {
      font-size: 48px;
    }
  }

  .headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include desktop_only {
      height: 100%;
      align-items: center;
      flex-direction: row;
    }
  }

  img.el-woman {
    width: 108px;
  }

  h2.last {
    align-self: flex-end;

    @include desktop_only {
      margin-bottom: 0;
      margin-left: 8px;
      align-self: center;
    }
  }

  h3 {
    font-size: 18px;
    color: #FBF6F6;
    text-align: left;
    text-shadow: 0 2px 8px rgba(0,0,0,0.50);
  }

  .top {
    padding: 20px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    @include desktop_only {
      padding-top: 20px 12px 0;
      height: 253px;
      justify-content: flex-start;
    }
  }


  ul {
    list-style-type: none;
  }

  li {
    margin-top: 8px;
    font-size: 12px;
    list-style-position: inside;
    display: flex;
    flex-direction: row;
  }

  .sections {
    display: flex;
    flex-direction: column;

    @include desktop_only {
      flex-direction: row;
      height: 460px;
    }
  }

  .section {
    padding: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 0px;

    @include desktop_only {
      padding: 15px 20px;
    }

    h3.section-header {
      font-size: 16px;
      color: #FBF6F6;
      text-align: left;
      text-shadow: 0 2px 8px rgba(0,0,0,0.50);
      margin-bottom: 8px;
      @media (min-width: 350px) {
        font-size: 18px;
      }
    }
  }

  #BackNext {
    @include desktop_only {
      margin-top: -45px;
    }
  }
  .wait {
    background-image: linear-gradient(180deg, rgba(94,176,170,0.81) 0%, rgba(27,119,110,0.00) 99%);
    display: flex;
    flex-direction: column;

    .pros {
      @include desktop_only {
        margin-left: 10px;
        max-width: calc(100% - 160px)
      }
    }

    .block {
      display: flex;
      flex-direction: row;
    }

    h3 {
      color: $wait-green;
      font-weight: bold;
      text-shadow: none;
      @include desktop_only {
        padding-right: 20px;
        //max-height: 30px;
      }
    }

    li::before {
      content: "•";
      margin-right: 5px;
      color: $wait-green;
    }

  }

  .pros-list {
    margin-left: 10px;
    margin-top: 10px;
    max-width: calc(100vw - 160px);
  }

  .cons-list {
    margin-left: 10px;
    margin-top: 10px;
    max-width: calc(100vw - 160px);
  }

  .schedule {
    background-image: linear-gradient(180deg, rgba(88,82,160,0.67) 0%, rgba(27,119,110,0.00) 99%);

    .pros {
      height: 100%;
      @include desktop_only {
        margin-left: 10px;
        max-width: calc(100% - 160px);
        align-self: flex-start;
      }
    }

    img {
      align-self: flex-start;
    }

    h3 {
      color: $schedule-purple;
      font-weight: bold;
      text-shadow: none;
      @include desktop_only {
        padding-right: 20px;
      }
    }

    li::before {
      content: "• ";
      margin-right: 5px;
      color: $schedule-purple;
    }
  }
}
