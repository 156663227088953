@import '../../scss-mixins/mixins';

#BackNext {

  //background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  //padding: 0 12px 20px 12px;
  padding-bottom: 15px;
  z-index: 3;
  width: 100%;

  @include desktop_only {
    margin-top: 70px;
    align-items: flex-start;
    padding: 0 20px 15px;
    box-sizing: border-box;
  }
  img.back {
    height: 17px;
  }

  img.next {
    height: 17px;
  }

  img.hbp {
    margin-top: -12px;
    height: 36px;
    padding-bottom: 0;
  }

  img.cs {
    height: 17px;
    @include desktop_only {
      margin-top: -3px;
      height: 19px;
    }
  }
}