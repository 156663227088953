@import '../../scss-mixins/mixins';

#Q4 {

  background-image: url('/Q4.png'), radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;

  .mobile {
    @include desktop_only {
      display: none;
    }
  }

  .desktop {
    @include mobile_only {
      display: none;
    }
  }

  @include desktop_only {
    background-image: none;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .right {
    text-align: right;
    z-index: 3;
  }

  .back-next-desktop {
    width: 100%;
    max-width: 940px;
    padding-top: 53px;
    //align-self: flex-end;
  }

  .headers {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include desktop_only {
      justify-content: center;
    }
  }

  h1 {
    font-size: 39px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    line-height: 40px;
    width: 50%;
    margin-bottom: 20px;

    @media (min-width: 340px) {
      width: 45%;
      font-size: 41px;
    }

    @include desktop_only {
      width: 550px;
      font-size: 52px;
      line-height: 59px;
      margin: 0;
    }

    @media (min-width: 940px) {
      font-size: 57px;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    color: #FBF6F6;
    text-align: center;
    text-shadow: 0 2px 8px rgba(0,0,0,0.50);
  }

  .desktop {
    .bottom {
      background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100vw;
      max-width: 940px;
    }

    .wait {
      background-image: linear-gradient(180deg, rgba(94,176,170,0.81) 0%, rgba(27,119,110,0.00) 99%);
      flex: 1 1 0px;
      height: 50vh;
      @media (min-width: 940px) {
        height: 550px;
      }
      
    }

    .schedule {
      flex: 1 1 0px;
      height: 50vh;
      background-image: linear-gradient(180deg, rgba(88,82,160,0.67) 0%, rgba(27,119,110,0.00) 99%);
      @media (min-width: 940px) {
        height: 550px;
      }
    }

    .content {
      padding-top: 40px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    ul {
      margin-top: 20px;
      margin-bottom: 30px;
      max-width: 590px;
    }
    
    li {
      margin-top: 10px;
      margin-left: 10px;
      font-size: 18px;
    }

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .top {
    padding: 0 12px;
    height: 200px;

    @media (min-width: 350px) {
      height: 230px;
    }

    @media (min-width: 400px) {
      height: 260px;
    }

    @media (min-width: 450px) {
      height: 60vw;
    }

    @include desktop_only {
      padding-top: 20px;
      height: 270px;
      background-image: url('/Q4_background.png');
      background-position: 100% 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      img {
        height: 270px;
        margin-right: 0;
        margin-top: 24px;
        @include desktop_only {
          margin-left: -320px;
        }
      }
    }
  }

  .calendars {
    padding: 0 12px;

    @include desktop_only {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .popup-link {
    margin-bottom: 20px;

    @include desktop_only {
    }

    button {
      padding-left: 33px;
      width: 100%;
      font-size: 16px;
      color: #0096DA;
      text-align: left;
      text-decoration: underline;
    }
  }

  .popup {
    padding-right: 5px;

    h2.title {
      font-size: 17px;
      font-weight: bold;
      color: #8DC63F;
      text-align: left;
      margin-left: 12px;
      margin-bottom: 5px;
    }

    h4 {
      font-size: 14px;
      margin-left: 12px;
      margin-bottom: 5px;
    }

    p {
      font-size: 11px;

      @include desktop_only {
        font-size: 12px;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;

      img {
        height: 73px;
        margin-right: 5px;
      }
    }
    .row.with-column {
      align-items: flex-start
    }

    .column {
      margin-top: 4px;
      p {
        margin-bottom: 8px;
      }
    }

    .column {
      display: flex;
      flex-direction: column;

    }
  }

  .calendar-plus {
    height: 270px;
  }
}
