@import '../../scss-mixins/mixins';

#ValuesQuestion {
  background-color: #C8E9F1;
  width: 100%;

  h1 {
    font-size: 25px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    margin-top: 25px;
    margin-bottom: 25px;
    line-height: 36px;

    @include desktop_only {
      font-size: 42px;
      line-height: 47px;
    }

    @media (min-width: 940px) {
      font-size: 50px;
      line-height: 57px;
      margin: 0;
    }
  }

  .locator {
    width: 66%;

    @include desktop_only {
      margin-top: 20px;
      width: auto;
      height: 18px;
    }
  }

  .headers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 130px;
  }

  .top {
    padding: 20px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 0 auto;
    height: 80vh;
    margin-bottom: -13vh;
    width: 100%;

    @media (min-width: 350px) {
      height: 75vh;
      margin-bottom: -15vh;
    }
    @media (min-width: 400px) {
      height: 75vh;
    }

    @include desktop_only {
      height: 75vh;
      margin-bottom: -18vh;
    }

    @media (min-width: 940px) {
      margin-bottom: -85px;
      height: 700px;
    }
  }

  .bottom {
    background-image: linear-gradient(180deg, rgba(88,82,160,0.48) 0%, rgba(27,119,110,0.00) 84%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 31vh;

    @media (min-width: 350px) {
      height: 38vh;
    }

    @media (min-width: 400px) {
      height: 38vh;
    }

    @include desktop_only {
      height: 200px;
    }
  }
}
