@import '../../scss-mixins/mixins';

#ValuesQ5 {

  h1 {
    @include desktop_only {
      width: 65%;
    }

    @media (min-width: 940px) {
      width: 100%;
    }
  }

  .headers {
    @media (min-width: 400px) {
      width: 90%;
    }
    @media (min-width: 940px) {
      width: 62%;
    }
  }
}
