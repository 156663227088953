@import '../../scss-mixins/mixins';

#CSection {
  background-color: #C8E9F1;

  h1 {
    font-size: 45px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    @include desktop_only {
      margin-left: 12px;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    @include desktop_only {
      font-size: 45px;
    }
  }
   
  p {
    margin-top: 10px;
    font-size: 16px;
    color: #000000;
    text-align: left;
  }

  .top {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 204px;

    @include desktop_only {
      padding-top: 20px;
      height: 262px;
      justify-content: flex-start;
    }

    .titles {
      display: flex;
      flex-direction: column;
      @include desktop_only {
        flex-direction: row;
      }
    }
  }

  .headers {
    display: flex;
    flex-direction: column;
    @include desktop_only {
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }

  .sections {
    display: flex;
    flex-direction: column;

    @include desktop_only {
      flex-direction: row;
    }
  }

  .section-header {
    display: flex;
    flex-direction: column;
    height: 180px;
    align-items: center;
  }

  .section {
    padding: 12px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 420px;

    @media (min-width: 375px) {
      height: 130vw;
    }

    @include desktop_only {
      height: 500px;
      padding: 12px 55px;
      flex: 1 1 0px;
    }

    p.hundred {
      margin-top: 0;
      font-size: 14px;
      line-height: 18px;
      color: #FBF6F6;
      text-shadow: 0 2px 8px rgba(0,0,0,0.50);
      font-weight: bold;
      text-align: center;
    }

    button {
      margin-top: 10px;
      margin-bottom: 20px;
      color: white;
      border-radius: 4px;
      font-family: Kefa-Bold;
      font-size: 16px;
      padding: 3px 6px;
      color: #FBF6F6;
      text-align: center;
      line-height: 20px;
      text-shadow: 0 2px 8px rgba(0,0,0,0.50);

      @include desktop_only {
        margin: 18px 0 20px;
      }

      @media (min-width: 940px) {
        margin: 32px 0 32px;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .walls {
      height: 100%;
      width: 100%;
      @media (min-width: 940px) {
        margin-top: 10px;
        width: 360px;
      }
      position: relative;

      img {
        width: calc(10%);
      }

      img.mom {
        // Ratio of width to height is 23/42
        margin: 0 calc((10% - 10%*23/42)/2);
        width: calc(10%*23/42);
        transition: all 1s ease-out;
      }

      img.fetus {
        opacity: 0;
        transition: all 2s ease-out;
      }

      img.fade.mom {
        opacity: 0;
      }

      img.fade.fetus {
        opacity: 1;
      }

      img.fade.fetus.c-section {
        opacity: .7;
      }

      @for $i from 1 through 100 {
        img.fetus:nth-child(#{$i}) {
          transition-delay: #{$i * 0.020 + 1.75}s;
        }
        img.mom:nth-child(#{$i}) {
          transition-delay: #{$i * 0.020 + 1.75}s;
        }
      }
    }

    .wall {
      position: absolute;
    }

    .text-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin: 2px 0;
        width: calc(10% - 4px);

        @include desktop_only {
          width: 10%;
        }

        @media (min-width: 940px) {
          width: 36px;
        }
      }

      img.csection {
        opacity: 0.7;
      }
    }

    .result-text {
      margin-top: 5px;
      width: 100%;

      p {
        margin-top: 0;
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
        color: #FBF6F6;
        text-align: left;
        text-shadow: 0 2px 8px rgba(0,0,0,0.50);
      }
    }
  }

  .hundred-text {
    display: flex;
    flex-direction: column;
  }

  .wait {
    background-image: linear-gradient(180deg, rgba(94,176,170,0.81) 0%, rgba(27,119,110,0.00) 99%);

    button {
      background-color: rgba(19,123,135,0.45);
      border: 1px solid #137B87;
    }
  }

  .schedule {
    background-image: linear-gradient(180deg, rgba(88,82,160,0.67) 0%, rgba(27,119,110,0.00) 99%);

    button {
      background-color: rgba(88,82,160,0.38);
      border: 1px solid #5852A0;
      border-radius: 4px;
    }
  }

  .sources {
    text-decoration: underline;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 25px;
    @include desktop_only {
      margin-top: 20px;
      margin-bottom: -36px;
    }
  }
}
