@import '../../scss-mixins/mixins';

#Background {

  @media (min-width: 940px) {
    height: 100vh;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .child-content {
    max-width: 940px;
    @media (min-width: 940px) {
      width: 940px;
    }
  }

}