@import '../../scss-mixins/mixins';

#Welcome {
  //background-color: $light-blue;
  background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);

  a {
    text-decoration: underline;

  }

  h1 {
    font-size: 48px;
    font-weight: bold;
    line-height: 56px;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    line-height: 32px;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #FBF6F6;
    text-align: center;
  }

  p {
    font-size: 14px;
    line-height: 18px;

    @include desktop_only {
      font-size: 16px;
      font-weight: bold;
    }
  }

  li {
    margin-top: 5px;
  }

  .content {
    margin-top: 10px;
  }

  .intro {
    padding: 20px 15px;
    @include desktop_only {
      padding: 30px;
    }
  }

  .wait {
    flex: 1 1 0px;
    background-image: linear-gradient(180deg, rgba(94,176,170,0.81) 0%, $light-blue 99%);
    padding: 20px;
  }

  .wait-or-schedule {
    display: flex;
    flex-direction: column;

    @include desktop_only {
      flex-direction: row;
    }

    h3 {
      text-align: left;
    }
  }

  .bullet-content {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    li {
      color: $wait-green;
    }

    @include desktop_only {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  ul {
    //list-style: none;
    margin-left: 25px;
    flex: 1 1 0;
  }
  
  .bullet-text {
    color: black;
    margin-left: -5px;

    p {
      line-height: 15px;
      font-size: 12px;
    }
  }
  
  .or {
    margin: 3px 0;
    border: 1px solid #979797;
    //box-shadow: 0 2px 7px 0 rgba(0,0,0,0.50);
    box-shadow: 0 2px 7px 0 $schedule-purple;
    background-color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    @include desktop_only {
      display: none;
    }
  }

  img {
    object-fit: contain;
    align-self: flex-start;
    //flex: 1 1 0;

    @include desktop_only {
      flex: unset;
    }
  }

  .bubble {
    margin: 25px 0;
    background: rgba(115,196,216,0.75);
    border: 1px solid #81BCCA;
    border-radius: 8px;
    padding: 10px 16px;

    @include desktop_only {
      background: none;
      border: none;
    }
  }

  .bubble-text {
    font-size: 16px;
    font-weight: bold;
    color: $white-text;
    text-align: center;
    line-height: 20px;
    text-shadow: 0 2px 8px rgba(0,0,0,0.50);

    @include desktop_only {
      color: black;
      text-shadow: none;
    }
  }

  .schedule {
    flex: 1 1 0px;
    padding: 20px 25px 20px 10px;
    background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
    background-image: linear-gradient(180deg, rgba(88,82,160,0.67) 0%, rgba(27,119,110,0.00) 99%);
    display: flex;
    flex-direction: column;

    li {
      color: $schedule-purple;
    }
  }

  .bottom {
    background: $light-blue;
    background: #DEF7FD;
    background: rgba(27,119,110,0.00);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;

    @include desktop_only {
      padding: 20px;
    }
  }

  .sources {
    text-align: center;
    a {
      font-size: 12px;
      color: #000000;
      text-align: center;
      line-height: 18px;
    }
  }

  .next {

    margin-top: 5px;
    align-self: flex-end;
    img {
      width: 52px;
      height: 17px;
    }

    .mobile {
      @include desktop_only {
        display: none;
      }
    }
    .desktop {
      //color: white MIKE, fix this up when ;
      color: black;
      font-weight: bold;
      @include mobile_only {
        display: none;
      }

    }
  }
}
