@import '../../scss-mixins/mixins';

#Q6 {
  background-image: url('/Q6.png'), radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;

  .mobile {
    height: 300px;
    @include desktop_only {
      display: none;
    }
  }

  @include desktop_only {
    background-image: none;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .right {
    text-align: right;
    z-index: 3;
    @include desktop_only {
      margin-left: -120px;
    }
  }
  
  .headers {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include desktop_only {
      justify-content: center;
    }
  }

  h1 {
    font-size: 38px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    line-height: 40px;
    width: 60%;
    margin-bottom: 20px;

    @media (min-width: 440px) {
      width: 45%;
    }

    @include desktop_only {
      width: 60%;
      font-size: 57px;
      line-height: 59px;
      margin: 0;
    }
    @media (min-width: 940px) {
      width: 100%;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    color: #FBF6F6;
    text-align: center;
    text-shadow: 0 2px 8px rgba(0,0,0,0.50);
  }

  .back-next-desktop {
    margin-top: -56px;
    width: 100vw;
    max-width: 940px;
  }

  .desktop {
    @include mobile_only {
      display: none;
    }

    .bottom {
      background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100vw;
      max-width: 940px;
    }

    .wait {
      background-image: linear-gradient(180deg, rgba(94,176,170,0.81) 0%, rgba(27,119,110,0.00) 99%);
      flex: 1 1 0px;
      height: 50vh;
      @media (min-width: 940px) {
        height: 550px;
      }
    }

    .schedule {
      background-image: linear-gradient(180deg, rgba(88,82,160,0.67) 0%, rgba(27,119,110,0.00) 99%);
      flex: 1 1 0px;
      height: 50vh;
      @media (min-width: 940px) {
        height: 550px;
      }
    }

    .content {
      padding-top: 40px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center
    }

    ul {
      margin-top: 20px;
      margin-bottom: 30px;
      max-width: 550px;
    }
    
    li {
      margin-top: 10px;
      margin-left: 10px;
      font-size: 18px;

      ul {
        list-style: none;
        margin-bottom: 2px;
        margin-top: 2px;
      }

      li {
        padding-bottom: 0;
      }

      li::before {
        content: "— ";
        margin-right: 0;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .both-calendar {
      display: absolute;
      padding-bottom: 10px;
      height: unset;

      img {
        background-image: none;
        width: 57px;
        padding-left: 42px;
      }

      img.wait {
        margin-left: -32px;
        height: unset;
      }

      img.schedule {
        margin-top: -35px;
        height: unset;
      }
    }
  }

  .top {
    padding: 0 12px;
    height: 200px;

    @media (min-width: 350px) {
      height: 230px;
    }

    @media (min-width: 400px) {
      height: 260px;
    }

    @media (min-width: 450px) {
      height: 60vw;
    }

    @include desktop_only {
      padding-top: 20px;
      background-image: url('/Q6_background.png');
      background-size: unset;
      height: 270px;
      background-position: 100% 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      img {
        height: 270px;
        margin-right: 0;
        margin-top: 2px;
        margin-left: -160px;
      }
    }

    @include mobile_only {
      .figure {
        display: none;
      }
    }
  }

  .calendars {
    padding: 0 12px;

    @include desktop_only {
      padding: 0;
      display: flex;
      flex-direction: row;
    }
  }

  .calendar-container {
    height: 270px;
  }
}
