@import '../../scss-mixins/mixins';

#ValuesQ6 {
  .headers {
    @media (min-width: 940px) {
      width: 98%;
    }
  }

  h1 {
    @include desktop_only {
      font-size: 36px;
    }
    @media (min-width: 940px) {
      width: 100%;
    }
  }
}
