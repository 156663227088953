@import '../../scss-mixins/mixins';

#Stories {
  background-color: #C8E9F1;

  h1 {
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    text-align: left;
  }

  p + p {
    margin-top: 10px;

  }

  h3 {
    font-size: 18px;
    color: #FBF6F6;
    text-align: left;
    text-shadow: 0 2px 8px rgba(0,0,0,0.50);
  }

  .top {
    padding: 20px 12px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    @include desktop_only {
      padding: 20px 12px 10px;
      height: 265px;
      justify-content: flex-start;
    }
  }

  .side-by-side {
    display: flex;
    flex-direction: row;

  }

  .sections {
    display: flex;
    flex-direction: column;
    @include desktop_only {
      flex-direction: row;
    }
  }

  .section {
    padding: 12px;
    display: flex;
    flex-direction: column;
    @include desktop_only {
      padding: 30px;
    }
    @media (min-width: 940px) {
      width: 470px;
    }

    img {
      margin-right: 10px;
    }

    img.el-woman {
      @include desktop_only {
        //width: 110px; // Messes up IE. Not sure why.
      }
    }

    h3.section-header {
      font-size: 16px;
      color: #FBF6F6;
      text-align: left;
      text-shadow: 0 2px 8px rgba(0,0,0,0.50);
      margin-bottom: 8px;
      @media (min-width: 350px) {
        font-size: 18px;
      }
    }

    .story {
      padding: 5px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 12px;
      color: #FCF6F5;
      line-height: 15px;
      text-shadow: 0 2px 8px rgba(0,0,0,0.50);
      margin-top: 5px;
      margin-bottom: 21px;

      p {
        padding-left: 1em;
        text-indent: -0.5em;
      }
    }
    .story.less-margin {
      margin-top: -15px;
    }
  }

  .headers {
    display: flex;
    flex-direction: column;
    @include desktop_only {
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }

  .sources {
    text-align: center;
    text-decoration: underline;

  }

  .wait {
    background-image: linear-gradient(180deg, rgba(94,176,170,0.81) 0%, rgba(27,119,110,0.00) 99%);

    .story {
      background: rgba(19,123,135,0.45);
      border: 1px solid #137B87;
    }

    h3 {
      color: $wait-green;
      font-weight: bold;
      text-shadow: none;
    }

    h3.wait-header {
      font-size: 16px;
      color: #FBF6F6;
      text-align: left;
      text-shadow: 0 2px 8px rgba(0,0,0,0.50);
    }
  }

  .schedule {
    background-image: linear-gradient(180deg, rgba(88,82,160,0.67) 0%, rgba(27,119,110,0.00) 99%);

    .story {
      background: rgba(88,82,160,0.50);
      border: 1px solid #5852A0;
    }

    h3 {
      color: $schedule-purple;
      font-weight: bold;
      text-shadow: none;
    }
  }
}
