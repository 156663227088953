@import '../../scss-mixins/mixins';

#QuestionNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;


  @include mobile_only {
    display: none;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
    width: 81px;
    height: 24px;

    border: 1px solid #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.50) inset;
    margin-right: 5px;
  }

  .item.q1 {
    background-image: linear-gradient(160deg, #AADFEF 0%, #FFFFFF 100%);
     
    span {
      font-size: 8px;
      color: #86888C;
      text-align: center;
      line-height: 10px;
    }
  }

  .item.q2 {
    background-image: linear-gradient(163deg, #FDC177 0%, #FFFFFF 100%);
  }

  .item.q3 {
    background-image: linear-gradient(168deg, #ADDFE6 0%, #FFFFFF 100%);
  }

  .item.q4 {
    background-image: linear-gradient(163deg, #BAD762 0%, #FFFFFF 100%);
  }

  .item.q5 {
    background-image: linear-gradient(166deg, #F4CCC8 0%, #FFFFFF 100%);
  }

  .item.q6 {
    background-image: linear-gradient(166deg, #FFEC9F 0%, #FFFFFF 100%);
  }

  .item.selected {
    border: none;
    background-image: none;
    box-shadow: none;
  }

  span {
    font-weight: bold;
    font-size: 8px;
    color: #86888C;
    text-align: center;
    line-height: 10px;
  }

}