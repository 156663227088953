@import '../../scss-mixins/mixins';

#Q1 {
  background-image: url('/Q1.png'), radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
  background-size: contain;
  background-repeat: no-repeat;

  .mobile {
    @include desktop_only {
      display: none;
    }
  }

  .desktop {
    @include mobile_only {
      display: none;
    }
  }

  @include desktop_only {
    background-image: url('/Q1_background.png');
    background-size: cover;
  }

  h1 {
    font-size: 39px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    line-height: 40px;
    width: 80%;
    max-width: 300px;
    margin-bottom: 20px;
    
    @include desktop_only {
      margin: 0;
      font-size: 52px;
      line-height: 55px;
      width: 550px;
      max-width: none;
    }

    @media (min-width: 920px) {
      font-size: 64px;
      line-height: 66px;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .headers {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include desktop_only {
      justify-content: center;
    }
  }

  .top {
    padding: 0 12px;
    height: 200px;

    @media (min-width: 350px) {
      height: 230px;
    }

    @media (min-width: 400px) {
      height: 260px;
    }

    @media (min-width: 450px) {
      height: 60vw;
    }

    @include desktop_only {
      padding-top: 20px;
      background-image: url('/Clouds.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 100% 0;
      height: 270px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      img {
        height: 270px;
        margin-top: 24px;
        z-index: 5;
      }
      .right {
        text-align: center;
      }
    }
  }

  .calendars {
    padding: 0 22px;

    @include desktop_only {
      padding: 0px 0 0;
      display: flex;
      flex-direction: row;
    }
  }

  .bottom {
    background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);

    @include desktop_only {
      height: 553px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include mobile_only {
      background-image: unset;
    }
  }

  .back-next-desktop {
    width: 100%;
    align-self: flex-end;
    padding-bottom: 100px;
    @include mobile_only {
      display: none;
    }
  }

}
