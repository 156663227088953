@import '../../scss-mixins/mixins';

#ValuesQ4 {
  h1 {
    @include desktop_only {
      font-size: 40px;
      width: 79%;
    }

    @media (min-width: 940px) {
      font-size: 36px;
      width: 100%;
    }
  }

  .headers {
    @media (min-width: 375px) {
      width: 95%;
    }
    @media (min-width: 940px) {
      width: 75%;
    }
  }
}