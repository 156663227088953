@import '../../scss-mixins/mixins';

#About {

  h1 {
    font-size: 46px;
    font-weight: bold;
    line-height: 45px;
  }

  h2 {
    font-size: 29px;
    font-weight: bold;
    line-height: 32px;

    @include desktop_only {
      font-size: 25px;
      text-align: left;
    }
  }

  p {
    font-size: 14px;
    line-height: 18px;
    //font-weight: bold;

    @include desktop_only {
      font-size: 16px;
    }
  }

  .sections {
    display: flex;
    flex-direction: column;

    @include desktop_only {
      flex-direction: row;
    }
  }

  .intro-and-content {
    @include desktop_only {
      flex: 1 1 0px;
      padding-right: 20px;
    }
  }

  .top-page {
    padding: 20px 12px;
    background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);

    @include desktop_only {
      padding: 30px;
    }
  }

  .bullet-content {
    margin-top: 33px;
    @include desktop_only {
      margin-top: 0;
    }
  }

  .time {
    margin-top: 10px;
    @include desktop_only {
      padding-bottom: 20px;
      text-align: center;
    }
  }

  .bullet-text {
    font-size: 14px;
    color: #000000;
    text-align: left;
    line-height: 18px;
  }
  
  li {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  li::before {
    margin-right: 15px;
  }
  li.first::before {
    content: url(/i.png);
  }

  li.then::before {
    content: url(/happy_sad.png);
  }

  li.end::before {
    content: url(/doc.png);
    margin-left: 7px;
    margin-right: 20px;
  }

  .help {
    margin-top: 20px;
    font-size: 14px;
    color: #000000;
    text-align: left;
    line-height: 18px;

    p {
      margin-top: 18px;
    }

    @include desktop_only {
      flex: 1 1 0px;
      margin-top: 0;
    }
  }

  .bottom-page {

    @include mobile_only {
      display: none;
    }

    padding: 20px;
    background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);
    h2 {
      font-size: 27px;
      margin-bottom: 18px;
    }
  }

  .start {

    @include desktop_only {
      display: none;
    }

    margin: 15px 0;
    display: flex;
    flex-direction: column;

    a {
      align-self: flex-end;
    }

    img {
      height: 17px;
      text-align: right;
    }
  }

  .questions {
    display: flex;
    flex-direction: column;

    @include desktop_only {
      flex-direction: row;
    }

    button {
      width: calc((100vw - 45px) / 2);
      height: calc((100vw - 45px) * 1.473262 / 2);
      background-repeat: no-repeat;
      background-size: contain;

      @include desktop_only {
        width: calc((100vw - 45px) / 6);
        height: calc((100vw - 45px) * 1.473262 / 6);
      }
        @media (min-width: 940px) {
          font-size: 16px;
          width: 150px;
          height: 210px;
        }

      p {
        margin-top: calc((100vw - 45px) * 1.473262 * 0.66 / 2);
        padding: 0 11px;
        text-align: center;
        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
        @include desktop_only {
          margin-top: calc((100vw - 45px) * 1.473262 * 0.66 / 6);
          font-size: 12px;
        }
        @media (min-width: 940px) {
          //margin-top: calc((100vw - 45px) * 1.473262 * 0.66 / 6);
          margin-top: 140px;
          font-size: 14px;
          width: 80%;
        }
      }
      p + p {
        margin-top: 0px;
      }
    }

    button.when {
      background-image: url(/buttonQ1.png);
    }

    button.labor {
      background-image: url(/buttonQ2.png);
    }

    button.water {
      background-image: url(/buttonQ3.png);
    }

    button.pain {
      background-image: url(/buttonQ4.png);
    }

    button.safe {
      background-image: url(/buttonQ5.png);
    }

    button.c-section {
      background-image: url(/buttonQ6.png);
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }


}
