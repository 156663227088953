@import '../../scss-mixins/mixins';

#CalendarBullets {
  background-image: radial-gradient(50% 100%, #F1FCFF 0%, #DEF7FD 100%);

  .mobile {
    @include desktop_only {
      display: none;
    }
  }

  .desktop {
    @include mobile_only {
      display: none;
    }
  }

  @include desktop_only {
    flex: 1 1 0px;
  }

  h3 {
    font-size: 15px;
    color: #FBF6F6;
    text-align: left;
    text-shadow: 0 2px 8px rgba(0,0,0,0.50);
    font-weight: bold;
    vertical-align: center;
    @include desktop_only {
      font-size: 19px;
    }
  }

  ul {
    list-style-type: none;
    padding-right: 10px;
    padding-top: 5px;
  }

  li {
    font-size: 12px;
    color: #000000;
    text-align: left;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    margin-right: 15px;

    @include desktop_only {
      font-size: 14px;
    }

    p + p {
      margin-top: 10px;
    }
  }

  .wait {
    background-image: linear-gradient(180deg, rgba(94,176,170,0.81) 0%, rgba(27,119,110,0.00) 99%);
    padding-left: 12px;

    li::before {
      content: "• ";
      margin-right: 5px;
      color: $wait-green;
    }
  }

  .both {
    background-image: linear-gradient(180deg, rgba(99,176,221,0.81) 1%, rgba(255,255,255,0.00) 99%);
    padding-left: 12px;

    h3 {
      font-size: 15px;
      color: #FBF6F6;
      text-align: left;
      text-shadow: 0 2px 8px rgba(0,0,0,0.50);
      font-weight: bold;
      vertical-align: center;
      @include desktop_only {
        font-size: 19px;
      }
    }

    li::before {
      content: "• ";
      margin-right: 5px;
      color: #0E97D7;
    }

    li {
      li {
        padding-bottom: 0;
      }
      li::before {
        content: "— ";
        margin-right: 0px;
      }
    }

  }

  .schedule {
    background-image: linear-gradient(180deg, rgba(88,82,160,0.67) 0%, rgba(27,119,110,0.00) 99%);
    padding-left: 12px;

    li::before {
      content: "• ";
      margin-right: 5px;
      color: $schedule-purple;
    }
  }

  .with-calendar {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
      padding-top: 12px;

      @include desktop_only {
        padding-top: 18px;
      }
    }

    img {
      width: 20%;
    }

    .both-calendar {
      display: absolute;
      padding-bottom: 10px;

      img {
        background-image: none;
        width: 57px;
        padding-left: 42px;
      }

      img.wait {
        margin-left: -32px;
      }

      img.schedule {
        margin-top: -35px;
      }
    }
  }
}
