@import '../../scss-mixins/mixins';

#Values {
  background-color: #C8E9F1;
  //height: 100vh;

  h1 {
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    margin-bottom: 5px;
    line-height: 40px;

    @include desktop_only {
      margin-top: 100px;
    }
  }

  p {
    margin-top: 15px;
    line-height: 21px;
    width: 100%;
  }

  .top {
    padding: 20px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 80vh;
    margin-bottom: -13vh;

    @media (min-width: 350px) {
      height: 75vh;
      margin-bottom: -15vh;
    }
    @media (min-width: 400px) {
      height: 75vh;
    }

    @include desktop_only {
      height: 75vh;
      margin-bottom: -18vh;
      padding: 20px 12px 15px 15px;
      padding: 20px 12px 0;
    }

    @media (min-width: 940px) {
      padding: 20px 12px 15px 15px;
      margin-bottom: -80px;
      height: 680px;
    }
  }

  .bottom {
    background-image: linear-gradient(180deg, rgba(88,82,160,0.48) 0%, rgba(27,119,110,0.00) 84%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 31vh;

    @media (min-width: 350px) {
      height: 38vh;
    }

    @media (min-width: 400px) {
      height: 38vh;
    }

    @include desktop_only {
      padding: 20px 0 15px;
      height: 165px;
    }
  }
}
