@import '../scss-mixins/mixins';

html, body {
  margin: 0;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  font-family: kefa;
}

strong {
  font-weight: bold;
}

ul {
  list-style-type: disc;
}

a {
  text-decoration: none;
}

html, body, #root, .App {
  width: 100%;
}


.App {
  font-family: "Kefa", "Arial", "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}
